.update-browser {
    display: block;
    padding-left: 0.25rem;
    i {
        color: #112A45;
        margin-right: 0.25rem;
    }
}

form.loginform {
    display: none;
}

.back {
    display: none;
}
